<template>
  <div class="live-conversation">
    <header v-if="room" class="live-inner-header">
      <button class="live-back">
        <i class="fas fa-arrow-left"></i>
      </button>
      <div class="live-photo">
        <div class="live-channel">
          <i class="fas fa-globe" v-if="room.channel === 'widget'"></i>
          <i
            class="fab fa-facebook-messenger"
            v-else-if="room.channel === 'messenger'"
          ></i>
          <i
            class="fab fa-whatsapp"
            v-else-if="room.channel === 'whatsapp'"
          ></i>
          <i
            class="fab fa-whatsapp"
            v-else-if="room.channel === 'whatsapp-java'"
          ></i>
          <i class="fab fa-google" v-else-if="room.channel === 'rcs'"></i>
          <i
            class="fab fa-telegram"
            v-else-if="room.channel === 'telegram'"
          ></i>
          <i
            class="fab fa-instagram"
            v-else-if="room.channel === 'instagram'"
          ></i>
          <i class="fas fa-envelope" v-else-if="room.channel === 'email'"></i>
          <i
            class="fab fa-google"
            v-else-if="room.channel === 'google-business'"
          ></i>
          <i class="fas fa-question" v-else></i>
        </div>
        <button>
          <i class="fas fa-user"></i>
        </button>
      </div>
      <div class="live-header-name">
        <span class="sub">{{ `${room.contact.first_name} ${room.contact.last_name}` }}</span>
        <span
          class="live-status badge badge-success"
          v-if="room.status === 'OPEN'"
        >
          Ativo
        </span>
        <!-- <span class="live-status global" v-else-if="$parent.currentRoom.parentRoomId.indexOf('global') !== -1">Finalizado</span> -->
        <span
          class="live-status badge badge-danger"
          v-else-if="room.localStatus === 'queue'"
          >Fila</span
        >
        <span
          class="live-status badge badge-danger"
          v-else-if="room.localStatus === 'transfer'"
          >Transferido</span
        >
        <span
          class="live-status badge badge-danger"
          v-else-if="room.status === 'CLOSE'"
          >Finalizado</span
        >
        <span
          class="live-status badge badge-info"
          v-else-if="room.status === 'offline'"
        >
          Pendente
        </span>
      </div>
      <div
        class="dropdown b-dropdown live-header-tools-m btn-group"
        id="__BVID__219"
      >
        <!----><button
          aria-haspopup="true"
          aria-expanded="false"
          type="button"
          class="btn dropdown-toggle btn-secondary"
          id="__BVID__219__BV_toggle_"
        >
          <i class="fas fa-ellipsis-v"></i>
        </button>
        <ul
          role="menu"
          tabindex="-1"
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="__BVID__219__BV_toggle_"
        >
          <li role="presentation">
            <button role="menuitem" type="button" class="dropdown-item">
              <i class="fas fa-print"></i> Imprimir
            </button>
          </li>
          <li role="presentation">
            <button role="menuitem" type="button" class="dropdown-item">
              <i class="fas fa-reply-all"></i> Transferir para outro agente
            </button>
          </li>
          <li role="presentation">
            <button role="menuitem" type="button" class="dropdown-item">
              <i class="fas fa-reply-all"></i> Transferir para departamento
            </button>
          </li>
          <li role="presentation">
            <button role="menuitem" type="button" class="dropdown-item">
              <i class="fas fa-reply-all"></i> Devolver para fila
            </button>
          </li>
          <li role="presentation">
            <button role="menuitem" type="button" class="dropdown-item">
              <i class="fas fa-columns"></i> Exibir informações
            </button>
          </li>
          <li role="presentation">
            <hr
              role="separator"
              aria-orientation="horizontal"
              class="dropdown-divider"
            />
          </li>
          <li role="presentation">
            <button role="menuitem" type="button" class="dropdown-item">
              <i class="fas fa-sign-out-alt"></i> Finalizar atendimento
            </button>
          </li>
        </ul>
      </div>
    </header>
    <div v-if="room" class="live-transcript">
      <template v-for="message in room.messages">
        <div
          class="live-message"
          :class="{
            me: message.type_user === 'AGENT',
            automatic: message.type_user === 'BOT',
            deleted: message.status === 'DELETED',
            fail: message.status === 'FAIL',
            'another-agent': message.type_user === 'AGENT' && !message.me,
          }"
          :key="`message_${message.id}`"
        >
          <div class="live-message-box">
            <div class="live-message-inner">
              <span
                class="badge bg-warning live-agent"
                v-if="message.type_user === 'BOT'"
                >Automatica</span
              >
              <span
                class="badge bg-primary live-agent"
                v-else-if="message.type_user === 'AGENT' && !message.me"
                >Agente</span
              >
              <div class="live-message-content">
                <div
                  class="live-message-attachments"
                  v-if="message.attachments && message.attachments.length > 0"
                >
                  <template v-for="file in message.attachments">
                    <video
                      :key="file.id"
                      v-if="file.type == 'video'"
                      :src="file.uri"
                      :type="file.media_type"
                      controls="true"
                    ></video>
                    <audio
                      :key="file.id"
                      v-else-if="file.type === 'audio'"
                      :src="file.uri"
                      :type="file.media_type"
                      controls
                      preload="metadata"
                    ></audio>
                    <img
                      :src="file.uri"
                      :key="file.id"
                      v-else-if="file.type === 'image'"
                    />
                    <template v-else>
                      <a
                        :href="file.uri"
                        :key="file.id"
                        target="_blank"
                        class="live-external"
                      >
                        <i class="fas fa-file"></i>
                        <span class="live-attach-name">{{ file.name }}</span>
                        <!-- <span class="live-attach-name">{{file.uri | basename}}</span> -->
                        <div class="live-attach-type">
                          {{ file.media_type || file.type }}
                        </div>
                      </a>
                    </template>
                  </template>
                </div>
                <div class="live-message-text">
                  {{ message.message }}
                </div>
              </div>
              <div class="live-message-details">
                <time
                  class="live-message-time"
                  :title="message.creation_date"
                  >{{ message.creation_date | relativeDate }}</time
                >
                <span
                  v-if="message.status === 'DELIVERED'"
                  class="live-msg-status"
                  >🗸</span
                >
                <span
                  v-else-if="message.status === 'READ'"
                  class="live-msg-status"
                  >🗸🗸</span
                >
              </div>
            </div>
            <div class="live-message-source">
              <template v-if="room.channel">
                <i class="fas fa-globe" v-if="room.channel === 'widget'"></i>
                <i
                  class="fab fa-facebook-messenger"
                  v-else-if="room.channel === 'messenger'"
                ></i>
                <i
                  class="fab fa-whatsapp"
                  v-else-if="room.channel === 'whatsapp'"
                ></i>
                <i
                  class="fab fa-whatsapp"
                  v-else-if="room.channel === 'whatsapp-java'"
                ></i>
                <i class="fab fa-google" v-else-if="room.channel === 'rcs'"></i>
                <i
                  class="fab fa-telegram"
                  v-else-if="room.channel === 'telegram'"
                ></i>
                <i
                  class="fab fa-instagram"
                  v-else-if="room.channel === 'instagram'"
                ></i>
                <i
                  class="fas fa-envelope"
                  v-else-if="room.channel === 'email'"
                ></i>
                <i
                  class="fab fa-google"
                  v-else-if="room.channel === 'google-business'"
                ></i>
                {{ room.channel === 'widget' ? 'webchat' : room.channel }}
              </template>
            </div>
          </div>
        </div>
      </template>
      <!----><!---->
    </div>
    <div v-else>
      <img src="@/assets/yup.gif" alt="yupchat mascote" class="live-home-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatConversation',
  components: {},
  props: {
    room: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.whatsapp-bg {
  background: #efeae2 url('/assets/img/chat-bg.png');
  margin: 1.25rem -1.25rem;
  padding: 1rem;
  pointer-events: none;
}

.whatsapp-message {
  display: inline-block;
  max-width: 65%;
  min-width: 224px;
}

.whatsapp-preview {
  margin: 0 0 0.1rem 0;

  & > p {
    margin: 0;
  }
}

.qt-loader {
  text-align: center;
  padding: 32px;
}

.recording-write {
  text-align: center;

  &::v-deep .spinner-grow {
    margin: 0 0.22em;
  }
}

.live-resume-contact {
  margin-top: 1em;

  &::v-deep > .card > .tabs {
    & > .card-header {
      padding-bottom: 0;

      & > .nav {
        margin-bottom: 0;
      }
    }
  }
}

.live-media-preview {
  background: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;

  video {
    background: #333;
  }

  & > * {
    pointer-events: none;
    min-height: 16px;
    min-width: 16px;
    display: block;
  }
}

.live-conversation {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
}

.live-old-load {
  padding: 1em;
  text-align: center;
}

.live-transcript {
  height: calc(100% - 172px);
  overflow: hidden;
  overflow-y: auto;

  &.readonly,
  &.live-transcript-old {
    height: calc(100% - 80px);
    background: url('data:image/svg+xml,%3Csvg width="220px" height="220px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">%3Cg fill="%23eee">%3Cpath fill="none" d="M0 0H24V24H0z"/>%3Cpath d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"/>%3C/g>%3C/svg>')
      bottom right no-repeat;
  }
}

.live-transcript-old + .live-transcript {
  height: 0 !important;
  padding: 0 !important;
}

.live-manager-actions {
  position: relative;
  height: 92px;
  text-align: right;

  &.live-resume-actions {
    width: 204px;
    position: absolute;
    bottom: 0;
    right: 0.5em;
  }

  width: 204px;
  position: absolute;
  bottom: 0;
  right: 0.5em;

  .live-manager-enter {
    color: #fff;
    background: #0e1421;
    padding: 1em 2em;
    border-radius: 50px;
    border: none;
    font-size: 9pt;
    display: block;
    width: 98%;
    height: 40px;
    white-space: nowrap;

    & ~ .live-manager-enter {
      margin-top: 0.3em;
    }

    &,
    i {
      vertical-align: middle;
    }

    i {
      font-size: 13pt;
      margin-right: 0.5em;
      color: #fff;
    }
  }
}

.live-input {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: normal;
  align-items: center;
  border-top: thin solid #e8e4e4;
  background: #fff;
  padding: 1em 1em 0 1em;
  height: 92px !important;
  position: relative;
  z-index: 90;

  @media (max-width: 800px) {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  & > * {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 57px;
    align-self: auto;
    order: 0;
    height: 62px;

    @media (max-width: 480px) {
      flex-basis: 45px;
    }
  }

  .live-file-preview {
    position: absolute;
    z-index: 90;
    left: 0;
    bottom: 90px;
    width: 100%;
    height: 60vh;
    max-height: 640px;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: #fff;
    border: 0 solid #e8e4e4;
    border-top-width: thin;
    box-shadow: 0 -31px 31px -22px rgba(0, 0, 0, 0.2);

    aside {
      min-width: 200px;

      img,
      audio,
      video {
        display: block;
        margin: 0 auto;
      }
    }

    &.live-record-audio {
      bottom: 0;
      z-index: 91;

      .record {
        text-align: center;

        .timer {
          font-size: 9pt;
        }

        .audio-finish {
          margin: 10px 0;
          border-radius: 48px;
          background: #f00;
          border: none;
          height: 92px;
          width: 92px;
          font-size: 32pt;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.02), 0 0 0 0 rgba(0, 0, 0, 0.06);
          animation-duration: 0.4s;
          animation-name: audioWave;
          animation-iteration-count: infinite;
          animation-direction: alternate;

          i,
          & {
            color: #fff !important;
          }

          @keyframes audioWave {
            from {
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.02),
                0 0 0 0 rgba(0, 0, 0, 0.06);
            }

            to {
              box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2),
                0 0 0 4px rgba(0, 0, 0, 0.2);
            }
          }
        }

        footer {
          font-size: 7pt;
        }

        .row {
          padding: 20px 0;
        }

        .row > div {
          padding: 0;
        }

        .audio-cancel,
        .audio-send {
          border-radius: 24px !important;
          color: #fff !important;
          width: 100%;
        }
      }
    }

    .card-header {
      height: 72px;

      button {
        background: transparent;
        border: none;
        padding: 0;

        i {
          font-size: 16pt;
        }
      }
    }

    .card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 72px);
      position: relative;

      aside {
        img,
        object,
        video {
          position: relative;
          border: thin solid #e8e4e4;
          object-fit: contain;
          height: 100%;
          max-height: 240px;

          @media (max-height: 500px) {
            max-height: 150px;
          }

          @media (max-height: 420px) {
            max-height: 110px;
          }
        }

        .d-flex {
          @media (max-height: 420px), (max-width: 240px) {
            font-size: 8pt;
          }
        }
      }
    }
  }

  .live-file-preview-meta {
    font-size: 9pt;
  }

  .live-input-out {
    flex-grow: 1;
    flex-basis: auto;
    // height: 52px;
    padding: 0 12px;
    height: auto !important;
  }
}

.live-input {
  border-top: thin solid #e8e4e4;
  background: #fff;
  padding: 0 1em;

  ::v-deep button > span,
  ::v-deep button > i {
    display: block;
  }

  .live-input-in {
    background: #e7e6e6;
    border-radius: 27px;
    position: relative;

    textarea {
      background: #fc0;
      width: calc(100% - 40px);
      border: none;
      background: transparent;
      // background: #fc0;
      margin: 7px 20px;
      resize: none;

      // padding: .5em 0 0 0;
      // max-height: 6em;

      padding: 0.4em 0 0 0;
      max-height: 3.2em;
    }
  }

  ::v-deep .emojis .popup-box-container button > span {
    display: block;
    height: 25px;
  }

  .live-templates,
  ::v-deep .emojis .popup-box-container button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    width: 48px;
    height: 62px;

    i {
      font-size: 18pt;
      color: #c1c7cd;
    }

    @media (max-width: 480px) {
      width: 22px;

      i {
        font-size: 12pt;
      }
    }
  }
}

/*
  .live-enter {
    float: right;
    height: 62px;
    width: 62px;
  }

  ::v-deep .emojis {
    float: left;
  }

  .live-templates, ::v-deep .emojis .popup-box-container button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    float: left;
    width: 48px;
    height: 62px;

    i {
      font-size: 18pt;
      color: #c1c7cd;
    }

    @media (max-width: 480px) {
      width: 22px;

      i {
        font-size: 12pt;
      }
    }
  }
}*/

.live-inner-header {
  border-bottom-width: thin;
  position: relative;

  .live-status {
    font-size: 7pt;
    font-weight: bold;
    vertical-align: middle;
  }

  .live-back {
    font-size: 14pt;
    float: left;
    width: 42px;
    height: 100%;
    background: transparent;
    border: none;

    &:hover {
      background: #fcfcfc;
    }

    @media (min-width: 801px) {
      display: none;
    }
  }

  & > .live-photo {
    margin: 18px 10px 0 10px;
    float: left;
    height: 48px;
    width: 48px;
    position: relative;
    border-radius: 0;
    background: transparent;

    .live-channel {
      position: absolute;
      right: -2px;
      top: -2px;
      font-size: 8pt;
      height: 16px;
      width: 16px;
      background: #fff;
      border-radius: 16px;
    }

    button {
      background: #dfdfdf;
      border: none;
      border-radius: 48px;
      float: left;
      height: 100%;
      width: 100%;
      font-size: 12pt;
      padding: 0;
      overflow: hidden;
      border-radius: 24px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  & > .live-header-name {
    padding-top: 28px;
    float: left;
    width: 50%;
    font-size: 12pt;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .sub {
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  @media (max-width: 920px) {
    & > .live-header-name {
      width: calc(80% - 100px);
    }

    .live-header-tools-m {
      width: 32px;
      margin-right: 15px;

      ::v-deep button.dropdown-toggle {
        background: #fff !important;
        border: none;
        padding: 8px;
        width: 100%;

        &::after {
          display: none;
        }
      }
    }
  }
}

.live-history-header {
  text-align: right;
  padding: 0.5em;
  border: solid #f7d85c;
  border-width: thin 0;
  background: #ffefad;
  font-size: 9pt;

  button {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    border: none;
    padding: 2px 10px;
    margin-left: 6px;
    transition: background-color 0.3s ease;
    vertical-align: middle;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.live-header-tools {
  padding: 21px 5px 0 5px;
  float: right;
  width: 220px;
  text-align: right;

  @media (max-width: 920px) {
    display: none;
  }

  &::v-deep button,
  .menu-item {
    border: none !important;
    padding: 0 !important;
    border-radius: 50%;
    height: 36px !important;
    width: 36px !important;
    margin-left: 5px;
    vertical-align: middle;
  }

  .menu-item {
    display: inline-block;

    &::v-deep .dropdown {
      &,
      button {
        color: #788db4 !important;
        margin: 0 !important;
      }

      button {
        box-shadow: none !important;
        background: transparent !important;
        border: none;
        padding: 8px;
        width: 100%;

        &::after {
          display: none;
        }

        &:hover,
        &:active,
        &:focus {
          box-shadow: none !important;
          background: #fafafa !important;
        }
      }
    }
  }

  button::v-deep {
    box-shadow: none !important;
    background: transparent !important;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none !important;
      background: #fafafa !important;
    }
  }
}

.live-header-tools-m {
  display: none;
  width: 20%;
  float: right;
  margin-top: 22px;
  text-align: center;

  @media (max-width: 920px) {
    display: block;
  }
}

.live-inner-input {
  border-top-width: thin;
}

.live-transcript {
  padding: 1.5em;
}

.live-message {
  font-size: 10pt;
  min-width: 240px;

  .live-agent {
    position: absolute;
    right: -10px;
    top: -10px;
    color: #fff;
    font-size: 7pt;
    text-transform: uppercase;
    padding: 0.5em !important;
    line-height: 1 !important;

    &.finished {
      background: #f00 !important;
    }
  }

  .live-message-box {
    min-width: 200px;
    max-width: 60%;
    float: left;

    .icon-fail {
      font-size: 8pt;
      color: #fff;
    }

    .live-message-source {
      padding: 4px 2px 20px 2px;
      font-size: 10pt;

      i {
        vertical-align: middle;
        margin-right: 2px;
      }
    }
  }

  .live-message-content {
    overflow-wrap: break-word;
    // word-break: break-all;
  }

  .live-message-content::v-deep {
    code {
      overflow-wrap: break-word;
      white-space: pre-wrap;
      color: inherit;
    }
  }

  .live-message-inner {
    position: relative;
    border: 1px solid #d6dadd !important;
    background: #fff;
    padding: 14px 14px 32px 14px;
    border-radius: 1em;
    letter-spacing: normal !important;

    .live-message-opts {
      position: absolute;
      right: 10px;
      top: 10px;

      &::v-deep .dropdown-toggle {
        visibility: hidden !important;
        background: transparent !important;
        padding: 0.1em 0.4em;
        height: auto;
        line-height: 1;
      }
    }

    &:hover .live-message-opts {
      &::v-deep .dropdown-toggle {
        visibility: visible !important;
        background: transparent !important;
        padding: 0.1em 0.4em;
        height: auto;
        line-height: 1;
      }
    }

    & > picture {
      position: absolute;
      right: -10px;
      top: -10px;
      color: #fff;
      background: #cfcfcf;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      overflow: hidden;
      margin-right: 4px;
      pointer-events: none;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    & > strong {
      display: block;
      padding-bottom: 0.4em;
    }

    .live-message-details {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 80%;
    }
    .live-message-time {
      opacity: 0.7;
      margin-right: 5px;
    }
  }

  &:not(.me) .live-message-inner {
    border-top-left-radius: 0;
  }

  &.another-agent .live-message-inner {
    background: #f5eaf9 !important;
  }

  &.automatic .live-message-inner {
    background: #ffedc7 !important;
  }

  &.me .live-message-inner {
    border-top-right-radius: 0 !important;
    background: #752de6 !important;
    border: none !important;
    color: #fff;
  }

  &.fail {
    .live-message-inner {
      background: var(--danger) !important;
      color: #fff;
    }
  }

  &.deleted {
    .live-message-content {
      color: #cfcfcf !important;

      & > span {
        font-style: italic;
      }
    }
  }

  &.me .live-message-box,
  &.automatic .live-message-box {
    float: right;

    .live-message-source {
      text-align: right;
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  .live-message-attachments {
    video,
    img,
    audio {
      max-height: 320px;
      max-width: 100%;
    }
  }

  &.upload {
    .live-message-box {
      .live-message-inner {
        padding-bottom: 14px;
        white-space: nowrap;

        > span {
          margin-right: 4px;
        }
      }

      text-align: center;
      min-width: 1px;
      max-width: 60%;
    }
  }
}

.toggle-meta {
  @media (min-width: 1231px) {
    display: none;
  }
}

.live-input-count {
  position: absolute;
  right: 22px;
  bottom: 2px;
  font-size: 7pt;

  .exceed {
    font-weight: bold;
    color: #f00;
  }
}

.live-speak,
.live-enter {
  cursor: pointer;
  padding: 0;
  float: right;
  width: 62px;
  height: 62px;
  border-radius: 62px;
  border: none;
  background: #d494ea;
  font-size: 16pt;
  transition: 0.3s background ease;
  outline: none;

  &:enabled,
  .live-speak {
    background: #752de6;
  }

  i {
    color: #fff;
    display: block;
  }
}

.live-enter::before {
  margin: 0 auto;
  display: block;
  content: '';
  height: 50%;
  width: 50%;
  background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 441.30001" version="1.0">%3Cpath fill="%23fff" d="M 39.500001,0.69252634 C 17.300001,5.1925263 1.4042055e-6,26.692526 1.4042055e-6,49.692526 c 0,3.2 5.3999999957945,26.200002 14.5999995957945,62.700004 8.1,31.7 16.7,65.7 19.2,75.4 l 4.4,17.8 H 129.9 c 85.2,0 91.8,0.1 95.1,1.8 10.7,5.3 10.7,21.1 0,26.4 -3.3,1.7 -9.9,1.8 -95.1,1.8 H 38.200001 l -4.4,17.7 c -2.5,9.8 -11.1,43.8 -19.2,75.5 -9.1999996,36.5 -14.5999995957945,59.5 -14.5999995957945,62.7 0,23.8 17.5999995957945,44.9 40.9999995957945,49.1 7.7,1.4 16.7,0.7 24.1,-1.9 12.6,-4.4 418.899999,-173.5 423.799999,-176.4 13.6,-8.1 23.1,-25.3 23.1,-41.7 0,-16.4 -9.5,-33.6 -23.1,-41.7 C 484,175.99253 77.900001,6.9925263 65.000001,2.3925263 c -6.6,-2.29999996 -18.6,-3.09999996 -25.5,-1.69999996 z"/>%3C/svg>')
    center no-repeat;
  background-size: contain;
}

/*
#live-attach {
  float: left;
}
*/

#live-attach-input {
  display: none;
}

.live-external {
  position: relative;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  display: block;
  color: #fff;
  overflow: hidden;

  & > i {
    color: inherit;
    text-align: center;
    vertical-align: middle;
    width: 62px;
    font-size: 42pt;
    padding: 8px;
    display: inline-block;
  }

  .live-attach-name {
    vertical-align: middle;
    padding: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 70px);
    display: inline-block;
    text-align: right;
    direction: rtl;
  }

  .live-attach-type {
    background: rgba(0, 0, 0, 0.15);
    padding: 3px 6px;
    clear: both;
  }
}

#live-attach ::v-deep {
  button {
    border: none !important;
    background: transparent !important;
    height: 62px !important;
    font-size: 18pt;
    box-shadow: none !important;

    &:hover,
    &:active {
      background: #fcfcfc !important;
    }
  }

  ul {
    top: auto !important;
    left: 0 !important;
    right: auto !important;
    bottom: 50px !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  @media (max-width: 480px) {
    button {
      height: 58px !important;
      font-size: 14pt;
      padding: 0 15px;
    }

    ul {
      bottom: 38px !important;
    }
  }
}
.icon-live {
  font-size: 18pt;
  color: #c1c7cd;
  float: left;
  margin-top: 18px;
}
.fab-item-image {
  text-align: center !important;
}
.fab-item-image i {
  display: block;
  color: rgb(140, 140, 140);
  text-align: center;
  margin-top: 8px;
}

.fa-spinner {
  animation: rotation 0.32s linear infinite;
  transform-origin: 50% 50%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
